//All actions for Login component
import { USER_LOGIN_SUCCESS, LOGOUT_SUCCESS, SHOW_ACCOUNT_INFO, SAVE_USER_INFO, GET_ALL_CATEGORIES, SITE_SELECTED, ACCOUNT_SELECT_GET, ON_CHECK_CONTRACT_STATUS_CLEAR } from "./actionTypes";
import { ACCOUNT_SELECT_SAVE, ACCOUNT_LOADING, CATEGORY_SELECT_SAVE } from "./actionTypes";
import { URL_GATEWAY, API_VERSION } from "../helpers/constants";
import { clear } from "redux-localstorage-simple";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { customSwaltAlert } from "../helpers";
const MySwal = withReactContent(Swal);
const eliminarScripts = () => {
    const scripts = document.querySelectorAll(`
    script[src="https://cdn.jsdelivr.net/npm/html2canvas@1.3.2/dist/html2canvas.min.js}"],
    script[src="https://code.jquery.com/jquery-3.6.0.min.js"],
    script[src="https://ayuda.ttncompany.com/assest/js/bsop.js"],
    script[src="https://cdn.jsdelivr.net/npm/html2canvas@1.3.2/dist/html2canvas.min.js"],
    script[src="https://cdn.jsdelivr.net/npm/sweetalert2@11"],
    link[href="https://ayuda.ttncompany.com/assest/css/bsop.css"]`);
    scripts.forEach((script) => {
        script.parentNode.removeChild(script);
    });
    const bsopMainElements = document.querySelectorAll('.bsop-main, .bsop-canvas-edit-main,.bsop-main-modal,.bsop-modal-backdrop,.bsop-spinner-main,.bsop-modal-main');
    if (bsopMainElements.length > 0) {
        bsopMainElements.forEach((element) => {
            element.remove();
        });
    }
};
const agregarScript = () => {
    const script1Exists = document.querySelector('script[src^="https://ayuda.ttncompany.com/assest/js/bsop.js"]');
    const script2Exists = document.querySelector('script[src^="https://code.jquery.com/jquery-3.6.0.min.js"]');
    if (!(script1Exists || script2Exists)) {
        const script1 = document.createElement('script');
        script1.src = "https://ayuda.ttncompany.com/assest/js/bsop.js";
        document.body.appendChild(script1);
        const script2 = document.createElement('script');
        script2.src = "https://code.jquery.com/jquery-3.6.0.min.js";
        document.body.appendChild(script2);
    }
};

export const verifyAccessToken = (token) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    fetch(`${URL_GATEWAY}${API_VERSION}/security/validate`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
        }
    })
        .then(response => response.json())
        .then(res => {
            if (!res.status || res.status > 300) {
                dispatch(logOutUser());
            }
        })
        .catch(err => console.error(err, "ERROR"));

};

export const validateUser = (user, pass) => (dispatch, getState) => {
    if (user && pass) {
        var data = {
            email: user,
            password: pass
        };
        fetch(`${URL_GATEWAY}${API_VERSION}/users/login/`, {
            method: "POST",
            body: JSON.stringify(data),
            mode: 'cors',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        })
            .then(response => response.json())
            .then(data => {
                const { status, message, user_data, access_token } = data;

                if (status > 300 || (!status && !access_token)) {
                    return MySwal.fire({
                        icon: 'error',
                        title: "Error",
                        text: message,
                    });
                }
                if (!user_data.have_password) {
                    return MySwal.fire({
                        icon: 'warning',
                        title: "Correo enviado",
                        text: "Se envío un correo electrónico para restablecer su contraseña."
                    });
                } else {
                    dispatch({
                        type: USER_LOGIN_SUCCESS,
                        payload: user_data,
                        Authorization: access_token
                    });
                    dispatch({
                        type: SAVE_USER_INFO,
                        payload: user_data,
                    });
                    agregarScript();
                }
            })
            .catch((err) => {
                console.error(err);
                MySwal.fire({
                    icon: 'error',
                    title: "Error al conectar",
                    text: "Ocurrió un error al intentar conectarse, por favor intente de nuevo."
                });
            });
    } else {
        MySwal.fire({
            icon: 'error',
            title: "Error al ingresar",
            text: "Debe ingresar correo y contraseña para acceder al sistema."
        });
    }
};

export const onLogout = async token => {
    const req = await fetch(`${URL_GATEWAY}${API_VERSION}/security/auth/logout`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: token,
        },
    });

    const res = await req.json();
    return res;
};

export const logOutUser = (doAfterError) => (dispatch, getState) => {
    const token = getState().loginReducer.Authorization;
    dispatch({
        type: ACCOUNT_LOADING,
        error: false,
        loading: true,
    });

    onLogout(token)
        .then(() => {
            clear({
                states: ['loginReducer', 'profileReducer', 'userReducer', 'checkContractStatusReducer'],
            });

            dispatch({
                type: ACCOUNT_LOADING,
                error: false,
                loading: false,
            });

            dispatch({
                type: SHOW_ACCOUNT_INFO,
                payload: false,
            });

            dispatch({
                type: LOGOUT_SUCCESS,
            });

            dispatch({
                type: ON_CHECK_CONTRACT_STATUS_CLEAR,
            });

            window.location.href = `${process.env.REACT_APP_URL_PROTOCOL}${process.env.REACT_APP_URL_BASE}/login`
                ;
        })
        .catch((error) => {
            console.error('Error during logout:', error);
            dispatch({
                type: ACCOUNT_LOADING,
                error: true,
                loading: false,
            });
            doAfterError?.();
        });
};

export const selectAccount = ({ userid, idAccount, idProfile, myPayroll, mySetting, redirect }) => (dispatch, getState) => {

    if (!userid) {
        console.error('User ID is undefined');
        return;
    }

    dispatch({
        type: ACCOUNT_LOADING,
        error: false,
        loading: true
    });

    const token = getState().loginReducer.Authorization;

    fetch(`${URL_GATEWAY}${API_VERSION}/composite/selectaccount/${userid}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
        },
    })
        .then(response => response.json())
        .then(data => {
            const { status, accounts } = data;

            if (status > 300) {
                dispatch({
                    type: ACCOUNT_LOADING,
                    error: true,
                    loading: false
                });
                return customSwaltAlert({
                    icon: 'warning',
                    title: "Su sesión ha finalizado",
                    text: 'Vuelva a iniciar sesión nuevamente para continuar',
                    confirmButtonText: 'Aceptar',
                    showCancelButton: false
                }).finally(() => {
                    window.location.replace(`${process.env.REACT_APP_URL_PROTOCOL}${process.env.REACT_APP_URL_BASE}`);
                });
            }

            if (!accounts || accounts.length === 0) {
                dispatch({
                    type: ACCOUNT_LOADING,
                    error: true,
                    loading: false
                });
                return customSwaltAlert({
                    icon: 'error',
                    title: "Cuentas no disponibles",
                    text: 'Este usuario no tiene cuentas accesibles',
                    confirmButtonText: 'Aceptar',
                    showCancelButton: false
                });
            }

            const account = accounts.find(el => el.id === Number(idAccount));
            const profile = account?.profile?.find(el => el.id === Number(idProfile));

            if (!account || !profile) {
                dispatch({
                    type: ACCOUNT_LOADING,
                    error: true,
                    loading: false
                });
                return customSwaltAlert({
                    icon: 'error',
                    title: "Cuenta no encontrada",
                    text: 'No se encontró la cuenta o el perfil especificado',
                    confirmButtonText: 'Aceptar',
                    showCancelButton: false
                });
            }

            dispatch(saveAccount({ ...account, profile }));

            dispatch({
                type: ACCOUNT_SELECT_GET,
                payload: accounts,
                loading: false,
            });

            if (myPayroll || mySetting) {
                dispatch(saveCategory('Configuración'));
            }

            dispatch({
                type: ACCOUNT_LOADING,
                error: false,
                loading: false
            });

            if (redirect) {
                const url = myPayroll ? 'miNomina/miNomina/hojaDeVida' : mySetting ? 'configuracion/miperfil' : 'nomina/inicio/';
                window.location.href = url;
            }
        })
        .catch((err) => {
            console.error("Fetch Error:", err);
            dispatch({
                type: ACCOUNT_LOADING,
                error: true,
                loading: false
            });
            customSwaltAlert({
                icon: 'error',
                title: "Error",
                text: 'Ocurrió un error al cargar la cuenta',
                confirmButtonText: 'Aceptar',
                showCancelButton: false
            });
        });
};

export const saveAccount = (account, doAfter) => (dispatch, getState) => {

    dispatch({
        type: ACCOUNT_SELECT_SAVE,
        payload: account,
    });

    doAfter?.();
};

export const saveCategory = (category, doAfter) => (dispatch, getState) => {
    dispatch({
        type: ACCOUNT_LOADING,
        error: false,
        loading: true
    });


    dispatch({
        type: CATEGORY_SELECT_SAVE,
        category: category,
    });

    setTimeout(() => {
        dispatch({
            type: ACCOUNT_LOADING,
            error: false,
            loading: false
        });
    }, 1000);


    if (!!doAfter) {
        doAfter();


    }
    // return MySwal.fire({
    //     icon:'success',
    //     title: "selecciono el módulo de "+category+" ",
    // });
};

export const getCategories = (id_profile) => (dispatch, getState) => {
    dispatch({
        type: GET_ALL_CATEGORIES,
        loadingCategories: true
    });

    const token = getState().loginReducer.Authorization;

    fetch(
        `${URL_GATEWAY}${API_VERSION}/security/permission/category/?id_profile=` + id_profile,
        {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token,
            },
        }
    ).then((response) => response.json())
        .then((res) => {
            const { row_total, all_Categories, new_category } = res;
            dispatch({
                type: GET_ALL_CATEGORIES,
                total: row_total,
                all_categories: all_Categories,
                newCategories: new_category,
                loadingCategories: false,
            });
        })
        .catch((err) => {
            console.error(err.message);
        });
};
export const forgotPassword = (account) => (dispatch, getState) => {
    fetch(`${URL_GATEWAY}${API_VERSION}/composite/forgotPassword/`, {
        method: "POST",
        body: JSON.stringify(account),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    })
        .then(response => response.json())
        .then(res => {
            if (res.success) {
                MySwal.fire({
                    icon: 'success',
                    title: '¡Correo enviado!',
                    text: `${account.email}`
                });
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `${res.message}`
                });
            }
        })
        .catch((err) => {
            console.error(err);
            MySwal.fire({
                icon: 'error',
                title: 'Error al cambiar contraseña',
                text: 'No se pudo cambiar la contraseña',
                footer: 'Si el problema persiste comuníquese con un asesor.'
            });
        });
};


export const setSite = () => (dispatch, getState) => {
    dispatch({
        type: SITE_SELECTED,
        payload: {
            siteId: "",
            siteName: ""
        }
    });
};